p {
    font-size: 15px;
}

.booting-view {
    text-align: center;
    padding: 15%;
}

.trialBanner {
    width: 60%;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
@media (max-width: 600px) {
    .trialBanner {
        width: 100%;
    }
}
