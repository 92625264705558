.success-animation,
.error-animation {
    padding-left: 15%;
    padding-right: 15%;
}

.ui-success,
.ui-error {
    height: auto;
    margin: 40px;
    width: 50%;
    text-align: center;
}

.success-animation svg {
    width: 150px;
    margin-bottom: 15px;
}

.ui-success {
    &-circle {
        stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
        stroke-dashoffset: 260.75219024795285px;
        transform: rotate(220deg);
        transform-origin: center center;
        stroke-linecap: round;
        animation: ani-success-circle 1s ease-in both 5s; /* Delay start by 5 seconds */
    }
    &-path {
        stroke-dasharray: 60px 64px;
        stroke-dashoffset: 62px;
        stroke-linecap: round;
        animation: ani-success-path 0.4s 1s ease-in both 5s; /* Delay start by 5 seconds */
    }
}

@keyframes ani-success-circle {
    to {
        stroke-dashoffset: 782.2565707438586px;
    }
}

@keyframes ani-success-path {
    0% {
        stroke-dashoffset: 62px;
    }
    65% {
        stroke-dashoffset: -5px;
    }
    84% {
        stroke-dashoffset: 4px;
    }
    100% {
        stroke-dashoffset: -2px;
    }
}

.ui-error {
    &-circle {
        stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
        stroke-dashoffset: 260.75219024795285px;
        animation: ani-error-circle 1.2s linear 5s; /* Delay start by 5 seconds */
    }
    &-line1 {
        stroke-dasharray: 54px 55px;
        stroke-dashoffset: 55px;
        stroke-linecap: round;
        animation: ani-error-line 0.15s 1.2s linear both 5s; /* Delay start by 5 seconds */
    }
    &-line2 {
        stroke-dasharray: 54px 55px;
        stroke-dashoffset: 55px;
        stroke-linecap: round;
        animation: ani-error-line 0.2s 0.9s linear both 5s; /* Delay start by 5 seconds */
    }
}

@keyframes ani-error-line {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes ani-error-circle {
    0% {
        stroke-dasharray: 0, 260.75219024795285px;
        stroke-dashoffset: 0;
    }
    35% {
        stroke-dasharray: 120px, 120px;
        stroke-dashoffset: -120px;
    }
    70% {
        stroke-dasharray: 0, 260.75219024795285px;
        stroke-dashoffset: -260.75219024795285px;
    }
    100% {
        stroke-dasharray: 260.75219024795285px, 0;
        stroke-dashoffset: -260.75219024795285px;
    }
}
