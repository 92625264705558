.title {
    height: 50px;
    font-size: 20px;
    line-height: 40px;
    border-bottom: 1px solid #d9d9d9;
}

.details {
    float: right;
    font-size: 13px;
    color: var(--p-color-icon-subdued);
}

.details a {
    text-decoration: none;
}

.details a .text {
    font-size: 90%;
    vertical-align: top;
    color: var(--p-color-icon-subdued);
}
.details a .Polaris-Icon {
    display: inline-block;
}

.content {
    width: 38%;
    margin-top: 15px;
    margin-right: 2%;
}

.card-monitor-view .Polaris-Box {
    min-height: 161px;
}

.kpi {
    margin-top: 10px;
}
.kpi > span:first-child {
    font-size: 22px;
    font-weight: bold;
    margin-right: 10px;
}
.kpi > span:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
}
.kpi > span:nth-child(2) svg {
    display: inline-block;
}

.content span {
    display: inline-block;
    vertical-align: top;
}

.chart {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    margin: 15px -20px 0;
}
.chartInner {
    align-items: center;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    margin: var(--p-space-4) calc(var(--p-space-4) * -1);
    margin-bottom: 0.5rem;
}

.trendindicator-percent {
    font-size: 10px;
    margin-left: 2px;
    position: absolute;
}

.loader {
    margin-top: 10px;
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 184, 0);
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
}
.loader::after {
    animation-delay: 1s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
