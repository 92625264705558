.insights-list {
    margin-bottom: 30px;
}

.insights-card {
    position: relative;
    /* Generated with https://fffuel.co/ffflux/ */
    border-radius: 8px;
    vertical-align: middle;
    padding: 20px;
    color: #fff;
    font-weight: 500;
}

.insight-card-link {
    display: block;
    text-align: right;
    color: #fff;
    font-weight: 700;
}

.insight-card-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
}

.insight-card-value-positive::before {
    content: '↗';
    color: green;
    margin-right: 2px;
    font-weight: 900;
}

.insight-card-value-negative::before {
    content: '↘';
    color: red;
    margin-right: 2px;
    font-weight: 900;
}

.insights-card-bg-1 {
    background: #06083d url('./insight-1.svg') no-repeat center center;
    background-size: cover;
}

.insights-card-bg-2 {
    background: #06083d url('./insight-2.svg') no-repeat center center;
    background-size: cover;
}

.insights-card-bg-3 {
    background: #06083d url('./insight-3.svg') no-repeat center center;
    background-size: cover;
}

.insights-card-bg-4 {
    background: #06083d url('./insight-4.svg') no-repeat center center;
    background-size: cover;
}

.insights-card-bg-5 {
    background: #06083d url('./insight-5.svg') no-repeat center center;
    background-size: cover;
}

.bounce {
    animation: bounce 1s ease 5s;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-18px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@media (min-width: 90em) {
    .insights-list .Polaris-Grid-Cell--cell_2ColumnXl .insights-card {
        min-height: 201px;
    }
}
