.footer-container {
    overflow: hidden;
    text-align: center;
}

.footer-container p {
    font-size: 90%;
}

.footer-banner {
    margin: 50px auto;
    width: 80%;
    max-width: 500px;
}

.footer-banner a {
    color: rgb(31, 31, 31);
    text-decoration: underline;
}
