.side-nav {
    background-color: #ffff;
    padding: 15px;
    border-radius: 8px;
}

.side-nav-item {
    border-radius: 8px;
    font-weight: var(--p-font-weight-medium);
    margin-bottom: 8px;
    padding: 8px;
}

.side-nav-item-active {
    background-color: #ebebeb;
}

.side-nav-item:hover {
    background-color: #ebebeb;
    cursor: pointer;
}

.side-nav-item .Polaris-Icon {
    float: left;
    margin-right: 13px;
}
