html {
    scroll-behavior: smooth;
}

.Polaris-Modal-CloseButton {
    display: none;
}

.Polaris-Layout__Section {
    position: relative;
}

/* .plans-view-onboarding .Polaris-ShadowBevel:first-child::before {
    content: none;
} */

.plans-view-onboarding > .Polaris-ShadowBevel {
    content: none !important;
    --pc-shadow-bevel-content-xs: none !important;
    --pc-shadow-bevel-box-shadow-xs: none !important;
}

.plans-content-container {
    width: 80%;
    margin: 15px auto;
}

.plans .Polaris-Divider {
    margin-bottom: 20px;
}

/* .plans .Polaris-Box {
    border-radius: 7px !important;
    padding: 23px;
    box-sizing: border-box;
} */

.plans .plan-box-4 .Polaris-Box {
    border-top: 15px solid black;
}

.plans .plan-box-5 .Polaris-Box {
    border-top: 15px solid rgb(218, 56, 68);
}

.benefit-container {
    width: 80%;
    margin: 15px auto;
    padding: 10px;
    outline: var(--p-border-width-1) solid transparent;
    border-top-left-radius: var(--p-border-radius-2);
    border-top-right-radius: var(--p-border-radius-2);
    border-bottom-left-radius: var(--p-border-radius-2);
    border-bottom-right-radius: var(--p-border-radius-2);
}

@media (max-width: 650px) {
    .benefit-container {
        width: 100%;
    }
}
